<template>
  <div class="flex-row header">
    <!-- 企业logo -->
    <div class="flex-content header-logo" @click="toHome">
      杭州妤点数据科技有限公司
    </div>
    <!-- tabs分类 -->
    <div class="flex-row header-tabs">
      <div class="flex-content tabs-item"
        v-for="(item, index) in tabsList"
        :style="{color: (currentTabIndex === index ? '#C90010' : '#999999')}"
        :key="index"
        @click="changeTabs(item)"
        >
         <span>{{item.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      currentTabIndex: 0,
      tabsList: [
        {
          id: 1,
          name: '首页',
          path: '/home'
        },
        {
          id: 2,
          name: '项目简介',
          path: '/introduction'
        },
        {
          id: 3,
          name: '招商合作',
          path: '/merchants'
        },
        {
          id: 4,
          name: '联系我们',
          path: '/contact'
        }
      ]
    }
  },
  methods: {
    changeTabs (val) {
      if (this.currentTabIndex === val.id - 1) return false
      this.currentTabIndex = val.id - 1
      this.$router.push(val.path)
    },
    toHome () {
      this.currentTabIndex = 0
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 900px) {
  .header {
    position: fixed;
    top: 0;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    .header-logo {
      cursor: pointer;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
    }
    .header-tabs {
      .tabs-item {
        // min-width: 1.5rem;
        white-space: nowrap;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        margin-left: .3rem;
      }
    }
  }
}
@media screen and (min-width: 900px){
  .header {
  width: 100%;
  height: 150px;
  justify-content: space-between;
  align-items: center;
  .header-logo {
    cursor: pointer;
    height: 72px;
    margin-left: 114px;
    font-size: 30px;
    font-weight: bold;
  }
  .header-tabs {
    margin-right: 137px;
    .tabs-item {
      min-width: 120px;
      font-size: 30px;
      font-weight: bold;
      margin-left: 84px;
      cursor: pointer;
    }
  }
}
}
</style>
